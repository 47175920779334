<template>
    <div class="Awarp">
        <dashCard class="carbonBox1" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">{{commonLangHandler('powerBox1_structure','能源结构', getZEdata)}}</template>
            <template slot="aside"></template>
            <div class="card-content1">
                <chartBoard ref="chart1" :option="chartOption1"/>
            </div>
            <div class="card-content2">
                <div class="subTable" v-if="detailInfo.arr.length>0">
                    <div class="subTable-list" v-for="(item,idx) in detailInfo.arr" :key="idx">
                        <span><i :style="{background: colorList[idx]}"> </i>{{ item.name }}</span>
                        <span>{{ toThousandsSeparator(item.value || 0) }} kWh</span>
                        <!-- <span class="percent-box" :class="[item.percent >= 0 ? 'up':'decline']" v-if="Number(item.percent)">
                            <i class="lowcode" :class="item.percent >= 0 ? 'icon-shang': 'icon-xia'"> </i>
                            <font>{{ Math.abs(item.percent) || 0 }} %</font>
                        </span>
                        <span class="percent-box" v-else>
                          {{item.percent}}
                        </span> -->
                    </div>
                </div>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <carbonBox1  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'carbonBox1',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                }
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
            "currentSelectDeptInfo.CODE": {
                handler: function(newVal,oldVal) {
                    if(oldVal) {
                        this.getData()
                    }
                },
                deep: true,
                immediate: true
            }
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                searchObj: {},
                chartOption1: {},
                detailInfo: {
                    arr: [],
                    chart1: {
                        seriesData: []
                    }
                },
                colorList: ["#c6c6c6", "#42ff48", "#B4D0FF"]
            }
        },
        computed: {
            // 当前登录用户信息
            currentuserinfo() {
                return this.$store.state.currentuserinfo
            },
            // 当前Dept信息
            currentSelectDeptInfo() {
                return this.$store.state.currentSelectDeptInfo
            },
        },
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
               
                if(!value) return 0

                value=value.toFixed(2)
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },
            //每月报警预警数
            getData() {
            var deptCode = 'JG221027043'
            var url =  Config.dev_url + "/api-apps-v2/api/v2/energy/structure";
            var data = {
                deptCode: this.currentSelectDeptInfo.CODE,
            }
            this.isLoading = true
            this.$axios.post(url,data).then(res=>{
                if(res.data.code==0){
                    //console.log(res)
                    this.detailInfo = {
                        arr: []
                    }
                    // this.detailInfo.average = res.data.data.average
                    // this.detailInfo.greenTotal = res.data.data.greenTotal
                    // this.detailInfo.total = res.data.data.total
                    // var chart1 = {
                    //     categoryData: res.data.data.categoryData,
                    //     seriesData: res.data.data.seriesData
                    // }
                    // this.detailInfo.chart1 = chart1
                    // this.initEchart()
                    let arr = res.data.data.filter(item=>{
                      return item.name !== "总供电量"
                    })
                    this.detailInfo.arr = arr
                    //console.log(arr)
                    var chart1 = {
                    seriesData: arr
                }
                let lang = sessionStorage.getItem("locale") || 'CH'
               
                if(lang=='EN'){
                    console.log(chart1.seriesData)
                    for(let item of chart1.seriesData){
                        if(item.name =='市电供电量'){
                            item.name = 'electric supply'
                        }
                        if(item.name =='光伏供电量'){
                            item.name = 'photovoltaic'
                        }
                    }
                }
                this.detailInfo.chart1 = chart1
                this.initEchart()

                }else{
                    // this.$message.warning(res.data.msg)
                }
            }).catch((wrong)=>{ }).finally(()=>{
                    this.isLoading = false
                })
            },
            getData0() {
                var chart1 = {
                    seriesData: [
                        {
                            name: this.LangHandler('pvelectric','光伏供电量'),
                            value: 261971,
                            percent: 3.8
                        },
                        {
                            name: this.LangHandler('energyelectric','储能供电量'),
                            value: 157182,
                            percent: 4.8
                        },
                        {
                            name: this.LangHandler('mainselectric','市电供电量'),
                            value: 10479,
                            percent: -1.8
                        },
                    ]
                }
                this.detailInfo.chart1 = chart1
                this.initEchart()
            },
            initEchart() {
                var {seriesData} = this.detailInfo.chart1
                var vm = this
              
                var sum = seriesData.reduce(function(prev, cur) {
                            return prev + cur.value;
                        }, 0)
                sum = this.toThousandsSeparator(sum)
                var colorList = this.colorList
                var option = {
                    animation:false,
                    layoutAnimation:false,
                    title: [
                        {
                            text: "",
                            subtext: sum,
                            textStyle: {
                                fontSize: 12,
                                color: "#333333",
                                fontFamily: 'ABBvoice_WCNSG_Rg',
                            },
                            subtextStyle: {
                                fontSize: 24,
                                fontFamily: 'ABBvoice_WCNSG_Rg',
                                color: "#1D2129",
                                fontWeight: '600',
                            },
                            textAlign: "center",

                            x: "50%",
                            y: "44%",
                        },
                        {
                            subtext: this.commonLangHandler('powerBox1_total','总能耗', this.getZEdata) + '(kWh)',
                            subtextStyle: {
                                fontSize: 12,
                                color: "#868686",
                                fontFamily: 'ABBvoice_WCNSG_Rg',
                            },
                            textAlign: "center",
                            x: "50%",
                            y: "52%",
                        },
                    ],
                    tooltip: {
                        trigger: 'item',
                        formatter: `{b} : {c} <br/> `+ vm.LangHandler('bepercent','所占百分比') + `: {d}%`
                        // formatter: function (params) {
                        //   let a = 0
                        //   if(Number(params.value)>9999){
                        //     a = (params.value/10000).toFixed(2) + "万"
                        //   }else{
                        //     a = params.value
                        //   }
                        //   return `${params.name} : ${a} <br/> vm.LangHandler('bepercent','所占百分比') : ${params.percent}%`
                        // }
                    },
                    series: [
                    {
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    return colorList[params.dataIndex]
                                },
                                borderColor: '#172659',
                                fontFamily: 'ABBvoice_WCNSG_Rg',
                                borderWidth: 0
                            }
                        },
                        type: 'pie',
                        radius: ['70%', '80%'],
                        center: ["50%", "50%"],
                        labelLine: {
                            normal: {
                                lineStyle: {
                                    color: '#34569D'
                                }
                            }
                        },
                        label: {
                            normal: {
                                show: false,
                                formatter: params => {
                                    return (
                                        '{name|' + params.name + '}{percent|' + params.percent.toFixed(0) + '%}'
                                    );
                                },
                                padding: [0, -5, 0, -5],
                            }
                        },
                        data: seriesData
                    }
                    ],
                }
                this.$nextTick(()=>{
                    this.updateChart('chart1', 'chartOption1', option)
                })

            },
            updateChart: function (refName, optionName, option = {}) {
                /* 渲染echart图 */
                if (!optionName) return
                this[optionName] = option
                setTimeout(() => {
                    if(!! this.$refs[refName]) {
                        this.$refs[refName].updateChartView()
                    }

                }, 500)
            },


        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }

    }
    </script>

<style lang="less" scoped>
.carbonBox1 {
    .card-content1 {
        flex: 1;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
    }
    .card-content2 {
        height: 142px;
        width: 100%;
        .subTable {
            height: 100px;
            display: flex;
            flex-direction: column;
            .subTable-list {
                width: 100%;
                height: 44px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                line-height: 44px;
                margin-top: 8px;
                box-shadow: 0px -1px 0px 0px #ebebeb inset;
                &:last-child {
                    box-shadow: none;
                }
                span {
                    display: flex;
                }
                i {
                    width: 4px;
                    height: 36px;
                    margin-right: 6px;
                    display: inline-block;
                }

                .percent-box {
                    padding: 0 7px;
                    height: 20px;
                    line-height: 20px;
                    display: inline-block;
                    text-align: center;
                    border-radius: 10px;
                    box-sizing: border-box;
                    i {
                        display: inline-block;
                        margin-right: 15px;
                    }
                }
                .up {
                    background: #fdeaeb;
                    color: #f03040;
                }
                .decline {
                    background: #e8f5ea;
                    color: #00B42A;
                }
            }
        }



    }
}
</style>
